import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

export default class ErrorBoundary extends Component {
  state = {
    error: null,
    eventId: null,
  };

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      this.setState({ error });
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        console.log("errorInfo", errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    } else {
      console.log("[ErroBoundary]", error);
    }
  }

  showReport = (eventId) => () => {
    Sentry.showReportDialog({ eventId: eventId });
  };

  goBack = () => {
    window.history.back();
  };

  render() {
    const { error, eventId } = this.state;
    if (error) {
      return (
        <>
          <button onClick={this.showReport(eventId)}>Report feedback</button>
          <button onClick={this.goBack}>Back</button>
        </>
      );
    } else {
      return this.props.children;
    }
  }
}
