import { LiveChatWidget } from "@livechat/widget-react";
import { useLivechatParams } from "./hooks/use-livechat-params";
import { useCurrentUser } from "utils/hooks";
import React from "react";

const Livechat = () => {
  const { currentUser } = useCurrentUser();
  const params = useLivechatParams();

  if (currentUser) {
    return <LiveChatWidget customerName={currentUser.fullName} customerEmail={currentUser.email} license={process.env.REACT_APP_LIVECHAT_LICENSE} sessionVariables={params} />;
  }

  return null;
};

export default Livechat;
