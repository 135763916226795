import React from "react";

const UserIcon = (props) => (
  <svg width="15px" height="16px" viewBox="0 0 15 16" version="1.1" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="Menu_actions" transform="translate(-344.000000, -236.000000)" stroke="#FFFFFF" strokeWidth="2">
        <g id="sidebar_menu_1-copy" transform="translate(309.000000, 0.000000)">
          <g id="Group-2" transform="translate(0.000000, 214.000000)">
            <g id="item" transform="translate(30.000000, 18.000000)">
              <g id="user">
                <g transform="translate(6.000000, 5.000000)">
                  <path
                    d="M12.4444444,14 L12.4444444,12.4444444 C12.4444444,10.7262252 11.0515526,9.33333333 9.33333333,9.33333333 L3.11111111,9.33333333 C1.39289189,9.33333333 0,10.7262252 0,12.4444444 L0,14"
                    id="Path"
                  ></path>
                  <circle id="Oval" cx="6.22222222" cy="3.11111111" r="3.11111111"></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UserIcon;
