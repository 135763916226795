import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  logOutHandler: () => {},
  setLoginToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const logOutHandler = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const setLoginToken = (token) => {
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  };

  return <AuthContext.Provider value={{ isLoggedIn, logOutHandler, setLoginToken }}>{children}</AuthContext.Provider>;
};
