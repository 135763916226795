import { fetcher } from "utils/axios";

export const createCategory = async ({ payload }) => {
  const response = await fetcher.post(`/admin/category`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getCategories = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await fetcher(`/admin/category/list`, { params });

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getCategory = async ({ id }) => {
  const response = await fetcher(`/admin/category/${id}`);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const updateCategory = async ({ id, payload }) => {
  const response = await fetcher.put(`/admin/category/${id}`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const deleteCategory = async ({ id }) => {
  const response = await fetcher.delete(`/admin/category/${id}`);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};
