import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getPageTitle } from "utils/links/pages";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    userSelect: "none",
  },
}));

const Breadcrumb = ({ history, location }) => {
  const classes = useStyles();

  const moveToHandler = (path) => () => {
    history.push(path);
  };

  const { title, path } = getPageTitle(location.pathname, location.search);
  return (
    <Typography variant="body1" noWrap className={classes.root} onClick={moveToHandler(path)}>
      {title}
    </Typography>
  );
};

export default withRouter(Breadcrumb);
