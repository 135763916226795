import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DotIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M24 24H0V0h24v24z"></path>
    <circle cx="6" cy="12" r="4"></circle>
  </SvgIcon>
);

export default DotIcon;
