const TEXT_LIMIT = 50;
const WEEKDAYS = [
  { label: "Sun", id: 0 },
  { label: "Mon", id: 1 },
  { label: "Tue", id: 2 },
  { label: "Wed", id: 3 },
  { label: "Thu", id: 4 },
  { label: "Fri", id: 5 },
  { label: "Sat", id: 6 },
];
const AWS_CHUNK_SIZE = 8 * 1024 * 1024; // 8MB
const CLOUDFLARE_CHUNK_SIZE = 20 * 1024 * 1024; // 20MB

export { TEXT_LIMIT, WEEKDAYS, AWS_CHUNK_SIZE, CLOUDFLARE_CHUNK_SIZE };

export const DND_ITEM_TYPES = {
  SECTION: "SECTION",
  SUBSECTION: "SUBSECTION",
  MODULE: "MODULE",
};

export const SHOP_URL = process.env.REACT_APP_SHOP_URL;
export const MINDHUB_URL = "https://www.academylogin.com";

export const HIDDEN_LIST = ["VISIBLE", "HIDDEN"];

export const KNOWLEDGE_LEVEL_MAP = {
  null: "All Levels",
  1: "Beginner",
  3: "Advanced",
};

export const KNOWLEDGE_LEVEL_OPTIONS = [
  { label: "All Levels", value: null },
  { label: "Beginner", value: 1 },
  { label: "Advanced", value: 3 },
];
