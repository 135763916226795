import React from "react";

const FlagIcon = ({ color, ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" stroke={color || "#FFF"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8">
      <path d="M.9 11.7s.9-.9 3.6-.9 4.5 1.8 7.2 1.8 3.6-.9 3.6-.9V.9s-.9.9-3.6.9S7.2 0 4.5 0 .9.9.9.9v10.8zM.9 18L.9 11.7" transform="translate(0 3)" />
    </g>
  </svg>
);

export default FlagIcon;
