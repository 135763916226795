import Axios from "axios";

export const fetcher = Axios.create({ baseURL: process.env.REACT_APP_API_URL });

fetcher.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem("token");

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return config;
});

fetcher.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMessage = "An error occurred during the request.";
    let { status, data } = error.response || {};

    if (data && data.message) {
      errorMessage = data.message;
    }

    return Promise.reject({ status, data, message: errorMessage });
  },
);
