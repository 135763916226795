import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const ScrollBar = ({ horizontal, vertical = true, ...props }) => {
  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      // backgroundColor: `red`,
    };
    return <div style={{ ...style, ...viewStyle }} {...props} />;
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: `#ffffff20`,
      borderRadius: 3,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars
      renderView={renderView}
      renderThumbHorizontal={horizontal && renderThumb}
      renderThumbVertical={vertical && renderThumb}
      // autoHide
      autoHideTimeout={1000}
      autoHideDuration={400}
      {...props}
    />
  );
};

export default ScrollBar;
