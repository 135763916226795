import { fetcher } from "utils/axios";

export const createLanguage = async ({ payload }) => {
  const response = await fetcher.post(`/admin/language`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getLanguages = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await fetcher(`/admin/language/list`, { params });

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getLanguage = async ({ id }) => {
  const response = await fetcher(`/admin/language/${id}`);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const updateLanguage = async ({ id, payload }) => {
  const response = await fetcher.put(`/admin/language/${id}`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const deleteLanguage = async ({ id }) => {
  const response = await fetcher.delete(`/admin/language/${id}`);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};
