import React from "react";

const MaterialIcon = ({ color, ...rest }) => (
  <svg width="21" height="21" viewBox="0 0 24 24" {...rest}>
    <g fill="none" fillRule="evenodd" stroke={color || "#FFF"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6" transform="translate(2 4)">
      <rect width="16" height="16" rx="1.744" />
      <path d="M4 0L4 16M12 0L12 16M0 8L16 8M0 4L4 4M0 12L4 12M12 12L16 12M12 4L16 4" />
    </g>
  </svg>
);

export default MaterialIcon;
