import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: 240,
  },
}));

const WowLogoIcon = ({ className, viewBox, color, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <svg viewBox={viewBox || "0 0 86 100"} width="100" height="100">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stopColor="#D62957" offset="0%"></stop>
            <stop stopColor="#881D39" offset="100%"></stop>
          </linearGradient>
          <polygon id="path-2" points="56.0248649 35.9448649 55.0972973 43.1405405 69.4162162 35.8172973 54.9610811 28.7610811"></polygon>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
            <stop stopColor="#BE1848" offset="0%"></stop>
            <stop stopColor="#931D3D" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
            <stop stopColor="#EF2541" offset="0%"></stop>
            <stop stopColor="#941D3D" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="logo">
          <path
            d="M65.4097297,19.8464865 C77.1783784,31.6118919 77.1783784,50.6875676 65.4097297,62.4562162 C53.6410811,74.2248649 34.5718919,74.2183784 22.8064865,62.4562162 C11.0410811,50.6940541 11.0410811,31.6151351 22.8064865,19.8497297 C34.5718919,8.08432432 53.6475676,8.07783784 65.4097297,19.8464865"
            id="Path"
            fill="#333651"
            fillRule="nonzero"
          ></path>
          <path
            d="M44.1124324,10.2745691 C36.1190114,10.2656229 28.4518068,13.4436258 22.8086486,19.1048649 C12.3556757,29.5524324 11.1967568,45.7740541 19.3189189,57.5145946 C20.672764,52.3725184 23.3695905,47.6829206 27.132973,43.9264865 C38.8983784,32.1610811 57.9751351,32.1610811 69.7405405,43.9264865 C71.0269128,45.2173767 72.1938332,46.6220232 73.227027,48.1232432 C75.8767568,38.092973 73.2810811,26.9632432 65.4162162,19.0994595 C59.7721696,13.4402032 52.105075,10.2641911 44.1124324,10.2745691"
            id="Path"
            fill="#292C42"
            fillRule="nonzero"
          ></path>
          <path
            d="M44.1254054,10.312431 C39.6641184,10.312431 35.2597112,11.3137123 31.2367568,13.2421622 C36.7385223,14.5322757 41.7616562,17.3561016 45.7232432,21.3859459 C57.4886486,33.3037838 57.4886486,52.6291892 45.7232432,64.5405405 C43.2849597,67.0147865 40.4363229,69.0477504 37.3037838,70.5491892 C47.4568479,72.93431 58.1185147,69.8468408 65.4259459,62.4054054 C77.1935135,50.4886486 77.1935135,31.1675676 65.4302703,19.2508108 C59.8171284,13.5312319 52.1392022,10.309986 44.1254054,10.312431"
            id="Path"
            fill="#25273B"
            fillRule="nonzero"
          ></path>
          <mask id="mask-3" fill="white">
            <use href="#path-2"></use>
          </mask>
          <use id="Mask" fill="url(#linearGradient-1)" href="#path-2"></use>
          <polygon
            id="Path"
            fill="url(#linearGradient-4)"
            points="44.9718919 39.08 43.1567568 53.1837838 56.9027027 46.1535135 57.8302703 38.9578378 56.7675676 31.7740541 42.8897297 25.0064865"
          ></polygon>
          <polygon
            id="Path"
            fill="url(#linearGradient-5)"
            points="31.2075676 63.7156757 44.8421622 56.7427027 46.6572973 42.6324324 44.5751351 28.5578378 30.8108108 21.8378378 33.9037838 42.7524324"
          ></polygon>
          <path
            d="M44.1297297,4.64864865 C24.175831,4.64864865 8,20.8244796 8,40.7783784 C8,60.7322771 24.175831,76.9081081 44.1297297,76.9081081 C64.0836285,76.9081081 80.2594595,60.7322771 80.2594595,40.7783784 C80.2594595,20.8244796 64.0836285,4.64864865 44.1297297,4.64864865 Z M44.1297297,8.10810811 C62.1730218,8.10810811 76.8,22.7350863 76.8,40.7783784 C76.8,58.8216704 62.1730218,73.4486486 44.1297297,73.4486486 C26.0864377,73.4486486 11.4594595,58.8216704 11.4594595,40.7783784 C11.4594595,22.7350863 26.0864377,8.10810811 44.1297297,8.10810811 Z"
            id="Oval"
            fill="#EA2742"
            fillRule="nonzero"
          ></path>
          <polygon
            id="Path"
            fill="#fff"
            points="31.8702703 80.5405405 36.9945946 80.5405405 29.5351351 95.6972973 24.3675676 95.6972973 23.7837838 86.6594595 20.1081081 95.6972973 14.9405405 95.6972973 12.5405405 80.5405405 17.9027027 80.5405405 18.5513514 90.4648649 22.4432432 80.5621622 27.3297297 80.5405405 27.9783784 90.4648649"
          ></polygon>
          <path
            fill="#fff"
            d="M45.3081081,81.1351351 C46.4540541,81.7513514 47.3405405,82.5945946 47.9783784,83.6756757 C48.6162162,84.7567568 48.9297297,85.9891892 48.9297297,87.372973 C48.9297297,89.0054054 48.5297297,90.4756757 47.7189189,91.7945946 C46.9081081,93.1135135 45.8054054,94.1513514 44.4108108,94.9081081 C43.0162162,95.6648649 41.4594595,96.0432432 39.7621622,96.0432432 C38.2810811,96.0432432 36.9621622,95.7297297 35.8162162,95.1135135 C34.6702703,94.4972973 33.7837838,93.6324324 33.1459459,92.5405405 C32.5081081,91.4486486 32.1945946,90.2054054 32.1945946,88.8216216 C32.1945946,87.1891892 32.5945946,85.7189189 33.4054054,84.4108108 C34.2162162,83.1027027 35.3189189,82.0756757 36.7135135,81.3297297 C38.1081081,80.5837838 39.6648649,80.2162162 41.3621622,80.2162162 C42.8432432,80.2162162 44.1621622,80.5189189 45.3081081,81.1351351 M39.2324324,84.9297297 C38.6810811,85.2864865 38.227027,85.7837838 37.8810811,86.4108108 C37.5351351,87.0378378 37.3621622,87.7297297 37.3621622,88.4756757 C37.3621622,89.1135135 37.4918919,89.6864865 37.7513514,90.2054054 C38.0108108,90.7243243 38.3567568,91.1351351 38.8,91.427027 C39.2432432,91.7189189 39.7189189,91.8702703 40.2378378,91.8702703 C40.8540541,91.8702703 41.4378378,91.6864865 41.9783784,91.3189189 C42.5189189,90.9513514 42.9513514,90.4540541 43.2756757,89.8162162 C43.6,89.1783784 43.7621622,88.4864865 43.7621622,87.7189189 C43.7621622,86.7567568 43.5027027,85.9567568 42.972973,85.3297297 C42.4432432,84.7027027 41.7837838,84.3891892 40.9945946,84.3891892 C40.3783784,84.3891892 39.7837838,84.572973 39.2324324,84.9297297"
            id="Shape"
          ></path>
          <polygon
            id="Path"
            fill="#fff"
            points="66.4432432 80.5405405 71.5675676 80.5405405 64.1081081 95.6972973 58.9405405 95.6972973 58.3567568 86.6594595 54.6810811 95.6972973 49.5135135 95.6972973 47.1135135 80.5405405 52.4756757 80.5405405 53.1243243 90.4648649 57.0162162 80.5621622 61.9027027 80.5405405 62.5513514 90.4648649"
          ></polygon>
          <path
            d="M67.9891892,95.2216216 C67.4810811,94.7567568 67.2324324,94.1945946 67.2324324,93.5351351 C67.2324324,92.7243243 67.5135135,92.0648649 68.0864865,91.5459459 C68.6594595,91.027027 69.372973,90.7675676 70.2378378,90.7675676 C71.027027,90.7675676 71.6756757,90.9945946 72.1837838,91.4378378 C72.6918919,91.8810811 72.9405405,92.4432432 72.9405405,93.1027027 C72.9405405,93.8918919 72.6486486,94.5621622 72.0756757,95.1027027 C71.5027027,95.6432432 70.7891892,95.9135135 69.9351351,95.9135135 C69.1459459,95.9135135 68.4972973,95.6864865 67.9891892,95.2216216 Z M69.7189189,80.5621622 L75.5567568,80.5621622 L72.7243243,89.9027027 L68.8324324,89.9027027 L69.7189189,80.5621622 Z"
            id="Shape"
            fill="#EE2741"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default WowLogoIcon;
