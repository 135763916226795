import { AppContext } from "contexts";
import { useContext } from "react";
import { getPermissionsFromPath, isVisible } from "utils/permissions";

const PermissionWrapper = ({ children }) => {
  const { permissions } = useContext(AppContext);

  const location = window.location;
  const requiredPermissions = getPermissionsFromPath(location.pathname) || [];
  const visible = isVisible(permissions, requiredPermissions);

  if (visible || location.pathname.includes("test")) {
    return children;
  }
  return null;
};

export default PermissionWrapper;
