import React from "react";

const ArrowLeftIcon = (props) => (
  <svg width="11px" height="10px" viewBox="0 0 11 10" version="1.1" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="User-Management_users" transform="translate(-299.000000, -42.000000)" stroke="#555E7F">
        <g id="top" transform="translate(300.000000, 29.000000)">
          <g id="back" transform="translate(0.000000, 8.000000)">
            <g id="arrow-left" transform="translate(0.000000, 5.000000)">
              <path d="M5,10 L0,5 L5,0 M10,5 L0,5" id="Path-2"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowLeftIcon;
