/* eslint-disable no-useless-computed-key */
import { useCurrentUser } from "utils/hooks";

export const useLivechatParams = () => {
  const { currentUser } = useCurrentUser();

  return {
    ["Exigo ID"]: currentUser?.customerId || "-",
    ["Legacy Rank"]: currentUser?.role?.type || "-",
    ["Country Code"]: currentUser?.country || "-",
    ["Email Address"]: currentUser?.email || "-",
    ["Language"]: currentUser?.languageType || "-",
  };
};
