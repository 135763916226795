import React from "react";

const CalendarIcon = ({ color, ...rest }) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" {...rest}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-789.000000, -352.000000)" fill={color || "#6B76A1"} fillRule="nonzero">
        <g transform="translate(300.000000, 336.000000)">
          <g>
            <g>
              <g transform="translate(489.000000, 16.000000)">
                <path d="M16.3333333,6.83333333 L16.3333333,4.8 C16.3333333,4.17407728 15.8259227,3.66666667 15.2,3.66666667 L13.2222222,3.66666667 L13.2222222,4 C13.2222222,4.36818983 12.9237454,4.66666667 12.5555556,4.66666667 C12.1873657,4.66666667 11.8888889,4.36818983 11.8888889,4 L11.8888889,3.66666667 L6.11111111,3.66666667 L6.11111111,4 C6.11111111,4.36818983 5.81263428,4.66666667 5.44444444,4.66666667 C5.07625461,4.66666667 4.77777778,4.36818983 4.77777778,4 L4.77777778,3.66666667 L2.8,3.66666667 C2.17407728,3.66666667 1.66666667,4.17407728 1.66666667,4.8 L1.66666667,6.83333333 L16.3333333,6.83333333 Z M16.3333333,8.16666667 L1.66666667,8.16666667 L1.66666667,15.2 C1.66666667,15.8259227 2.17407728,16.3333333 2.8,16.3333333 L15.2,16.3333333 C15.8259227,16.3333333 16.3333333,15.8259227 16.3333333,15.2 L16.3333333,8.16666667 Z M6.11111111,2.33333333 L11.8888889,2.33333333 L11.8888889,1 C11.8888889,0.631810167 12.1873657,0.333333333 12.5555556,0.333333333 C12.9237454,0.333333333 13.2222222,0.631810167 13.2222222,1 L13.2222222,2.33333333 L15.2,2.33333333 C16.5623024,2.33333333 17.6666667,3.43769762 17.6666667,4.8 L17.6666667,15.2 C17.6666667,16.5623024 16.5623024,17.6666667 15.2,17.6666667 L2.8,17.6666667 C1.43769762,17.6666667 0.333333333,16.5623024 0.333333333,15.2 L0.333333333,4.8 C0.333333333,3.43769762 1.43769762,2.33333333 2.8,2.33333333 L4.77777778,2.33333333 L4.77777778,1 C4.77777778,0.631810167 5.07625461,0.333333333 5.44444444,0.333333333 C5.81263428,0.333333333 6.11111111,0.631810167 6.11111111,1 L6.11111111,2.33333333 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
