import React, { useContext } from "react";
import List from "@material-ui/core/List";
import ScrollBar from "components/UI/ScrollBar";

import SideDrawerListItem from "./SideDrawerListItem";
import { isVisible } from "utils/permissions";
import { AppContext } from "contexts";

const SideDrawerList = ({ history, menuItems, selectedIndex, setSelectedIndex }) => {
  const { permissions } = useContext(AppContext);

  const onItemClickHandler = (index) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
    } else {
      setSelectedIndex(-1);
    }
  };

  return (
    <>
      <ScrollBar>
        <List>
          {menuItems.slice(0, menuItems.length - 1).map((item, index) =>
            // (role === ROLE_TYPES.SUPER_ADMIN || !item.roles) ?
            !item.hide && isVisible(permissions, item.permissions) ? (
              <SideDrawerListItem
                permissions={permissions}
                key={index}
                history={history}
                item={item}
                selected={index === selectedIndex}
                onClick={() => onItemClickHandler(index)}
              />
            ) : null,
          )}
        </List>
      </ScrollBar>
      <SideDrawerListItem history={history} item={menuItems[menuItems.length - 1]} selected={false} isSignOut={true} onClick={() => {}} />
    </>
  );
};

export default SideDrawerList;
