import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// import BellDotIcon from 'components/Icons/BellDotIcon';
// import MessageIcon from 'components/Icons/MessageIcon';
import BackIcon from "components/Icons/ArrowLeftIcon";
import Breadcrumb from "components/UI/Breadcrumb";
import ProfileMenu from "./ProfileMenu";
import { AppContext } from "contexts";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: theme.custom.layout.topAppBarHeight,
  },
  appBarShift: {
    width: `calc(100% - ${theme.custom.layout.drawerWidth}px)`,
    marginLeft: theme.custom.layout.drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolBar: {
    background: theme.palette.background.default,
    height: "100%",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  lightBlue: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  backLabel: {
    marginLeft: theme.spacing(2),
  },
  seperator: {
    height: theme.spacing(4),
    width: 1.5,
    margin: `0 ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.text.secondary,
  },
  iconButton: {
    margin: `0 ${theme.spacing(3)}px`,
  },
  autoLeft: {
    marginLeft: "auto",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
  },
  namePanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    minWidth: theme.spacing(22),
  },
  userEmail: {
    color: theme.palette.text.inactiveSubMenu,
  },
  avatar: {
    cursor: "pointer",
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
}));

const TopAppBar = ({ isOpenSidebar, history }) => {
  const classes = useStyles();
  const { currentUser } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const menuClickHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const backHandler = () => {
    window.history.back();
    // setOpenSidebar(!isOpenSidebar);
  };

  const onCloseHandler = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpenSidebar,
      })}
    >
      <Toolbar className={classes.toolBar}>
        <BackIcon className={classes.lightBlue} onClick={backHandler} />
        <Typography variant="body2" className={clsx(classes.lightBlue, classes.backLabel)} noWrap onClick={backHandler}>
          Back
        </Typography>
        <div className={classes.seperator} />
        <Breadcrumb />
        {/* <MessageIcon fontSize='large' className={clsx(classes.lightBlue, classes.iconButton, classes.autoLeft)} />
        <BellDotIcon className={clsx(classes.lightBlue, classes.iconButton)} /> */}
        <div className={clsx(classes.seperator, classes.autoLeft)} />
        {!!currentUser && (
          <div className={classes.userInfo}>
            <div className={classes.namePanel}>
              <Typography variant="caption">{currentUser.fullName}</Typography>
              <Typography variant="caption" className={classes.userEmail}>
                {currentUser.email}
              </Typography>
            </div>
            <Avatar className={classes.avatar} alt="avatar" src={currentUser.avatarURL} onClick={menuClickHandler} />
          </div>
        )}
        <ProfileMenu onClose={onCloseHandler} anchorEl={anchorEl} />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(TopAppBar);
