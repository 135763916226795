import React from "react";

const FolderIcon = (props) => (
  <svg viewBox="0 0 512 512" width="16" height="16" {...props}>
    <g>
      <g fill="#fff" strokeWidth="2">
        <path
          strokeWidth="36"
          fill="#fff"
          stroke="#FFFFFF"
          d="M469.333,85.333H249.75l-39.542-39.542c-2-2-4.708-3.125-7.542-3.125h-160C19.135,42.667,0,61.802,0,85.333v341.333
          c0,23.531,19.135,42.667,42.667,42.667h426.667c23.531,0,42.667-19.135,42.667-42.667V128
          C512,104.469,492.865,85.333,469.333,85.333z M490.667,426.667c0,11.76-9.573,21.333-21.333,21.333H42.667
          c-11.76,0-21.333-9.573-21.333-21.333V85.333C21.333,73.573,30.906,64,42.667,64H198.25l39.542,39.542
          c2,2,4.708,3.125,7.542,3.125h224c11.76,0,21.333,9.573,21.333,21.333V426.667z"
        />
      </g>
    </g>
  </svg>
);

export default FolderIcon;
