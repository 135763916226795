import { sidebarItems } from "utils/links/pages";
import { PERMISSION_TYPES } from "constants/types";

export const isVisible = (userPermissions, requiredPermissions) => {
  if (requiredPermissions.length === PERMISSION_TYPES.length) {
    return true;
  }
  return userPermissions.some((item) => requiredPermissions.includes(item));
};

// get required permissions from path
export const getPermissionsFromPath = (path) => {
  for (const sidebarItem of sidebarItems) {
    if (sidebarItem.link && path.includes(sidebarItem.link)) {
      return sidebarItem.permissions;
    }
    for (const sidebarSubItem of sidebarItem.subItems || []) {
      if (sidebarSubItem && sidebarSubItem.link && path.includes(sidebarSubItem.link)) {
        return sidebarSubItem.permissions;
      }
    }
  }
  return [];
};
