import { fetcher } from "utils/axios";

export const createEducator = async ({ payload }) => {
  const response = await fetcher.post(`/admin/educator`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getEducators = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await fetcher(`/admin/educator/list`, { params });

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getAllEducators = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await fetcher(`/admin/educator/all`, { params });

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const getEducator = async ({ id }) => {
  const response = await fetcher(`/admin/educator/${id}`);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const updateEducator = async ({ id, payload }) => {
  const response = await fetcher.put(`/admin/educator/${id}`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const deleteEducator = async ({ id }) => {
  const response = await fetcher.delete(`/admin/educator/${id}`);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};
