import React from "react";

const InfoIcon = (props) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g id="Menu_actions" transform="translate(-340.000000, -437.000000)" stroke="#FFFFFF" strokeWidth="2">
        <g id="sidebar_menu_1-copy" transform="translate(309.000000, 0.000000)">
          <g id="Group-2-Copy-2" transform="translate(0.000000, 416.000000)">
            <g id="item" transform="translate(28.000000, 18.000000)">
              <g id="user">
                <g id="info" transform="translate(4.000000, 4.000000)">
                  <circle id="Oval" cx="8" cy="8" r="8"></circle>
                  <line x1="8" y1="11.2" x2="8" y2="8" id="Path"></line>
                  <line x1="8" y1="4.8" x2="8.008" y2="4.8" id="Path"></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InfoIcon;
