import React from "react";

const AnalyticsIcon = (props) => (
  <svg width="16px" height="16px" viewBox="0 0 22 22" version="1.1" {...props}>
    <g fill="none" fillRule="evenodd" stroke={props.color || "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path d="M19.21 13.89c-1.75 4.139-6.046 6.602-10.502 6.022C4.252 19.33.73 15.849.098 11.4-.531 6.951 1.882 2.627 6 .83" transform="translate(1 1)" />
      <path d="M20 10c0-2.652-1.054-5.196-2.929-7.071C15.196 1.054 12.652 0 10 0v10h10z" transform="translate(1 1)" />
    </g>
  </svg>
);

export default AnalyticsIcon;
