import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "contexts";
import LoadingOverlay from "components/UI/LoadingOverlay";
import clsx from "clsx";

import SideDrawer from "containers/SideDrawer";
import TopAppBar from "containers/TopAppBar";
import ScrollBar from "components/UI/ScrollBar";
import PermissionWrapper from "hoc/PermissionWrapper";

const useStyles = makeStyles((theme) => {
  const mainPadding = theme.spacing(4);
  return {
    root: {
      display: "flex",
      flexDirection: "column",
    },
    body: {
      display: "flex",
      backgroundColor: theme.palette.background.default,
    },
    main: {
      position: "relative",
      flexGrow: 1,
      marginTop: theme.custom.layout.topAppBarHeight,
      minHeight: `calc(100vh - ${theme.custom.layout.topAppBarHeight}px)`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -theme.custom.layout.drawerWidth,
      // padding: mainPadding,
    },
    mainShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    loadingPanel: {
      width: `calc(100% - ${theme.custom.layout.drawerWidth}px)`,
      height: `calc(100vh - ${theme.custom.layout.topAppBarHeight}px)`,
      position: "fixed",
      left: theme.custom.layout.drawerWidth,
      top: theme.custom.layout.topAppBarHeight,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    mainBody: {
      padding: mainPadding,
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
    },
  };
});

const Layout = ({ children }) => {
  const classes = useStyles();
  const { isOpenSidebar, setOpenSidebar, loadingInfo } = useContext(AppContext);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <TopAppBar isOpenSidebar={isOpenSidebar} setOpenSidebar={setOpenSidebar} />
        <SideDrawer isOpenSidebar={isOpenSidebar} setOpenSidebar={setOpenSidebar} />
        <main
          className={clsx(classes.main, {
            [classes.mainShift]: isOpenSidebar,
          })}
        >
          <PermissionWrapper>
            <ScrollBar horizontal>
              <div className={classes.mainBody}>
                {children}
                {loadingInfo && (
                  <div className={classes.loadingPanel}>
                    <LoadingOverlay loading={loadingInfo} label={(loadingInfo || {}).label} />
                  </div>
                )}
              </div>
            </ScrollBar>
          </PermissionWrapper>
        </main>
      </div>
    </div>
  );
};

export default Layout;
