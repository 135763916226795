import React from "react";
import { store } from "react-notifications-component";
import { NotificationContent } from "components/UI/Notification";

const notification = {
  container: "top-center",
  insert: "top",

  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "faster", "fadeOut"],
  // slidingEnter: {
  //   duration: 300,
  //   timingFunction: 'linear',
  //   delay: 0
  // },

  // slidingExit: {
  //   duration: 300,
  //   timingFunction: 'linear',
  //   delay: 0
  // },

  // touchRevert: {
  //   duration: 600,
  //   timingFunction: 'linear',
  //   delay: 0
  // },

  // touchSlidingExit: {
  //   swipe: {
  //     duration: 600,
  //     timingFunction: 'linear',
  //     delay: 0
  //   },
  //   fade: {
  //     duration: 300,
  //     timingFunction: 'linear',
  //     delay: 0
  //   }
  // },

  dismiss: {
    duration: 5000,
    onScreen: false,
    pauseOnHover: true,
    waitForAnimation: false,
    showIcon: true,
    click: true,
    touch: true,
  },
};

const addNotification = ({ message, isError }) => {
  store.addNotification({
    ...notification,
    content: <NotificationContent message={message} isError={isError} />,
    message,
  });
};

const notifyMessage = (message) => {
  store.addNotification({
    ...notification,
    content: <NotificationContent message={message} />,
    message,
  });
};

const notifyError = (message = "") => {
  store.addNotification({
    ...notification,
    content: <NotificationContent message={message} isError={true} />,
    message,
  });
};

export { addNotification, notifyMessage, notifyError };
